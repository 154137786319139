/* .css-1iulo1y-MuiInputBase-root-MuiFilledInput-root:focus-within,
.css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input:focus-within, */
.css-14ok6bl-MuiInputBase-root-MuiFilledInput-root:focus-within, .scan_input:focus-within {
  background-color: yellow !important;
}
/* .rfid_scan.MuiInputBase-input.MuiFilledInput-input:focus-within .icon {
  background-color: yellow;
} */
.shipping_validation_text {
  color: #203860;
  font-size: 30px !important;
  font-weight: 700 !important;
}
.scan_message_box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.scan_input {
  min-width: 300px !important;
}
